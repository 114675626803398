import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

const AlertClose: FC<IconsProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 7L17 17M7 17L17 7"
      stroke="#8E8EAB"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertClose;
