import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

const Calendar: FC<IconsProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    color="#8E8EAB"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V7H20H4H3V6ZM20 9H21V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V9H4H20ZM8 11H7V13H8H12H13V11H12H8Z"
      fill="currentColor"
    />
    <path
      d="M16 3V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8 3V5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default Calendar;
