import { alpha, createTheme } from '@mui/material/styles';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import AlertClose from '@/components/icons/AlertClose';
import Calendar from '@/components/icons/Calendar';
import CheckBoxChecked from '@/components/icons/CheckBoxChecked';
import CheckBoxUnchecked from '@/components/icons/CheckBoxUnchecked';
import CheckCircle from '@/components/icons/CheckCircle';
import InfoOutlined from '@/components/icons/InfoOutlined';
import StepCheck from '@/components/icons/StepCheck';
import {
  primaryMain,
  primaryActive,
  primaryHover,
  textHeading,
  textParagraph,
  secondaryMain,
  secondaryHover,
  secondaryActive,
  captionColor,
  borderColor,
  backgroundCardExtraLayer,
  iconSecondary,
  screenBg,
  states,
  white,
  whiteLight60,
  whiteLight40,
  whiteLight20,
  textCaption,
  inputBorder,
  successMain,
  successMain20,
  primaryLight15,
  error,
  statusWarning,
  statusWarning20,
  statusError15,
  statusError20,
  statusPrimary10,
  statusSuccess20,
  textLightCaption,
  primaryLight,
  decorativeViolet,
  decorativePurple,
  decorativeMint,
  decorativeBlue,
  decorativeGreen,
  decorativeSky,
  decorativeOrange,
  primaryLight5,
  primaryLight10,
} from '@/styles/colors';

const baseTheme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 834,
      desktopL: 1440,
      desktopXL: 1920,
    },
  },
  palette: {
    primary: {
      main: primaryMain,
      hover: primaryHover,
      active: primaryActive,
      light: primaryLight,
      light5: primaryLight5,
      light10: primaryLight10,
      lightest: primaryLight15,
    },
    success: {
      main: successMain,
      lightest: successMain20,
    },
    secondary: {
      main: secondaryMain,
      hover: secondaryHover,
      active: secondaryActive,
    },
    text: {
      heading: textHeading,
      paragraph: textParagraph,
      caption: textCaption,
      lightCaption: textLightCaption,
    },
    black: {
      main: textHeading,
    },
    status: {
      warning: statusWarning,
      warning20: statusWarning20,
      error15: statusError15,
      error20: statusError20,
      primary10: statusPrimary10,
      success20: statusSuccess20,
    },
    white: {
      main: white,
      light: whiteLight60,
      lighter: whiteLight40,
      lightest: whiteLight20,
    },
    system: {
      caption: captionColor,
      border: borderColor,
      inputBorder: inputBorder,
      iconSecondary: iconSecondary,
      states: states,
    },
    background: {
      cardExtraLayer: backgroundCardExtraLayer,
      default: screenBg,
    },
    error: {
      main: error,
    },
    decorative: {
      blue: decorativeBlue,
      green: decorativeGreen,
      sky: decorativeSky,
      violet: decorativeViolet,
      purple: decorativePurple,
      mint: decorativeMint,
      orange: decorativeOrange,
    },
  },
});

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'light',
  },
  typography: {
    h1: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '40px',
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        fontSize: '48px',
        lineHeight: '56px',
      },
    },
    h2: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '32px',
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '32px',
        lineHeight: '32px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
    },
    h3: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '24px',
      fontWeight: '400',
      letterSpacing: '-1%',
      lineHeight: '28px',
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '28px',
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        fontSize: '32px',
        lineHeight: '36px',
      },
    },
    h3Bold: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '24px',
      fontWeight: '600',
      letterSpacing: '-1%',
      lineHeight: '28px',
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '28px',
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        fontSize: '32px',
        lineHeight: '36px',
      },
    },
    h4: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '28px',
      letterSpacing: 0,
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        lineHeight: '32px',
      },
    },
    h4Bold: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '28px',
      letterSpacing: 0,
      color: baseTheme.palette.text.heading,
      [baseTheme.breakpoints.up('tablet')]: {
        fontSize: '24px',
        lineHeight: '28px',
      },
      [baseTheme.breakpoints.up('desktopL')]: {
        lineHeight: '32px',
      },
    },
    h5: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '24px',
      color: baseTheme.palette.text.heading,
      letterSpacing: 0,
    },
    h5Bold: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '24px',
      color: baseTheme.palette.text.heading,
      letterSpacing: 0,
    },
    subheading: {
      fontFamily: 'var(--font-inter)',
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'uppercase',
      lineHeight: '20px',
      letterSpacing: '0.2%',
      color: baseTheme.palette.text.heading,
    },
    p1: {
      fontFamily: 'var(--font-inter)',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: 0,
      color: baseTheme.palette.text.paragraph,
    },
    p1Bold: {
      fontFamily: 'var(--font-inter)',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: 0,
      color: baseTheme.palette.text.paragraph,
    },
    p2: {
      fontFamily: 'var(--font-inter)',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: 0,
      color: baseTheme.palette.text.paragraph,
    },
    p2Bold: {
      fontFamily: 'var(--font-inter)',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: 0,
      color: baseTheme.palette.text.paragraph,
    },
    caption: {
      fontFamily: 'var(--font-inter)',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0.12px',
      color: baseTheme.palette.text.caption,
    },
    buttonL: {
      fontFamily: 'var(--font-poppins)',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 'normal',
          overflowX: 'hidden',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: Calendar,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: ({ theme }) => ({
          ':focus.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h3Bold: 'h3',
          h4: 'h4',
          h4Bold: 'h4',
          h5: 'h5',
          h5Bold: 'h5',
          h6: 'h6',
          subheading: 'div',
          p1: 'div',
          p2: 'div',
          p1Bold: 'div',
          p2Bold: 'div',
        },
      },
      styleOverrides: {
        noWrap: {
          width: 0,
          minWidth: '100%',
        },
      },
    },
    MuiTextField: {
      defaultProps: { fullWidth: true },
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          minWidth: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTabs-flexContainer': {
            gap: theme.spacing(3),
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        color: 'primary',
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.buttonL,
          padding: theme.spacing(1.5, 3),
          borderRadius: theme.spacing(5),
          textTransform: 'none',
          width: 'fit-content',
          minWidth: '48px',
          whiteSpace: 'nowrap',
          boxShadow: 'none',
          '& svg': {
            opacity: 0.6,
          },
          '&.Mui-disabled': {
            opacity: 0.4,
          },
        }),
      },
      variants: [
        {
          props: { color: 'primary', variant: 'contained' },
          style: ({ theme }) => ({
            background:
              'linear-gradient(291deg, #5232DB 8.88%, #037ED6 88.39%)',
            '&.Mui-disabled': {
              opacity: 0.4,
              color: theme.palette.white.main,
            },
            '&:hover': {
              background: `linear-gradient(${theme.palette.primary.hover}, ${theme.palette.primary.hover})`,
            },
            '&:active': {
              background: `linear-gradient(${theme.palette.primary.active}, ${theme.palette.primary.active})`,
              '& svg': {
                opacity: 1,
              },
            },
          }),
        },
        {
          props: { color: 'secondary', variant: 'contained' },
          style: ({ theme }) => ({
            color: 'white',
            '&.Mui-disabled': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
            },
            '&:hover': {
              backgroundColor: theme.palette.secondary.hover,
            },
            '&:active': {
              backgroundColor: theme.palette.secondary.active,
              '& svg': {
                opacity: 1,
              },
            },
          }),
        },
        {
          props: { color: 'warning', variant: 'contained' },
          style: ({ theme }) => ({
            color: 'white',
            backgroundColor: theme.palette.status.warning,

            '&.Mui-disabled': {
              backgroundColor: theme.palette.status.warning20,
              color: 'white',
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.status.warning20, 0.6),
            },
            '&:active': {
              backgroundColor: alpha(theme.palette.status.warning20, 0.6),
              '& svg': {
                opacity: 1,
              },
            },
          }),
        },

        {
          props: { color: 'black', variant: 'outlined' },
          style: ({ theme }) => ({
            color: theme.palette.black.main,
            borderColor: theme.palette.system.border,
            borderWidth: '2px',
            '& svg': {
              color: theme.palette.black.main,
            },
            '&.Mui-disabled': {
              backgroundColor: 'white',
              color: theme.palette.black.main,
            },
            '&:hover': {
              borderColor: theme.palette.system.caption,
              borderWidth: '2px',
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: 'white',
              borderColor: theme.palette.black.main,
              '& svg': {
                opacity: 1,
              },
            },
          }),
        },
        {
          props: { variant: 'text' },
          style: ({ theme }) => ({
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.hover,
            },
          }),
        },
        {
          props: { color: 'info', variant: 'text' },
          style: ({ theme }) => ({
            padding: 0,
            color: theme.palette.text.paragraph,
            background: 'transparent',

            '&:hover': {
              color: '#4728CD',
              background: 'transparent',
            },
            '&:active': {
              color: '#2E1A85',
              background: 'transparent',
            },
          }),
        },
        {
          props: { color: 'error', variant: 'contained' },
          style: ({ theme }) => ({
            color: 'white',
            backgroundColor: theme.palette.error.main,

            '&.Mui-disabled': {
              backgroundColor: theme.palette.error.main,
              color: 'white',
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.main, 0.6),
            },
            '&:active': {
              backgroundColor: alpha(theme.palette.error.main, 0.6),
              '& svg': {
                opacity: 1,
              },
            },
          }),
        },
        {
          props: { color: 'white', variant: 'contained' },
          style: ({ theme }) => ({
            color: theme.palette.text.heading,
            backgroundColor: theme.palette.white.main,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.white.main,
            },
            '&:hover': {
              backgroundColor: 'rgba(250, 250, 254, 1)',
            },
            '&:active': {
              backgroundColor: 'rgba(235, 235, 245, 1)',
            },
          }),
        },
      ],
    },
    MuiPagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPagination-ul': {
            gap: theme.spacing(0),
            [theme.breakpoints.up('tablet')]: {
              gap: theme.spacing(3),
            },
          },
          '& .MuiButtonBase-root': {
            margin: 0,
          },

          '& .MuiPaginationItem-text:not(:has(.MuiSvgIcon-root))': {
            ...theme.typography.p2Bold,
            color: theme.palette.text.heading,
          },

          '& .Mui-selected': {
            color: `${theme.palette.primary.main} !important`,
            backgroundColor: `${theme.palette.primary.lightest} !important`,
          },
        }),
      },
      variants: [
        {
          props: { color: 'primary' },
          style: ({ theme }) => ({
            '& button.Mui-selected': {
              backgroundColor: alpha(theme.palette.primary.main, 0.15),
              color: theme.palette.primary.main,
            },
            '& button.Mui-selected:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.25),
              color: theme.palette.primary.main,
            },
            '& .MuiPaginationItem-root:hover': {
              backgroundColor: 'rgba(235, 235, 245, 1)',
            },
          }),
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiOutlinedInput-root': {
            fontFamily: 'var(--font-inter)',
            borderRadius: theme.spacing(1.5),
            backgroundColor: 'transparent',
            '& > input:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${theme.palette.grey[50]} inset`,
            },
            '& .Mui-disabled': {
              color: theme.palette.text.heading,
              WebkitTextFillColor: theme.palette.text.heading,
            },
            '&.Mui-disabled': {
              opacity: 0.4,
            },
          },
        }),
        input: ({ theme, value }) => ({
          ...theme.typography.p1,
          color: theme.palette.text.heading,

          '& ~ .MuiOutlinedInput-notchedOutline': {
            borderColor: value ? '#D7D7EC' : '#EBEBF5',
          },

          '::placeholder': {
            color: theme.palette.text.caption,
            opacity: 1,
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.p1,
          color: theme.palette.text.caption,
          '&.Mui-disabled': {
            color: theme.palette.text.caption,
            opacity: 0.4,
          },
        }),
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          height: 0,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: () => ({
          '& .MuiChip-label': {
            fontFamily: 'var(--font-inter)',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '20px',
            letterSpacing: 0,
          },
        }),
      },
      variants: [
        {
          props: { color: 'secondary' },
          style: ({ theme }) => ({
            backgroundColor: alpha(theme.palette.secondary.main, 0.15),
            ...theme.typography.p2,
            color: theme.palette.secondary.main,
          }),
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          color: 'white',
          backgroundColor: theme.palette.text.paragraph,
          padding: '6px 8px',
          borderRadius: '4px',
          fontFamily: 'var(--font-inter)',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
          letterSpacing: '0.12px',
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({ borderRadius: theme.spacing(1.5) }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckBoxChecked fill={baseTheme.palette.primary.main} />,
        icon: <CheckBoxUnchecked />,
      },
    },
    MuiRadio: {
      defaultProps: {
        checkedIcon: <StepCheck fill="#2B57CD" />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiSvgIcon-root': {
            color: alpha(theme.palette.system.caption, 0.4),
            width: '26px',
            height: '26px',
          },
          '& svg': {
            opacity: 1,
          },
          '&:hover': {
            backgroundColor: 'unset',
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          height: '48px',
          padding: theme.spacing(0, 2),
          cursor: 'pointer',
          '&[aria-disabled="true"]': {
            opacity: '1!important',
            color: theme.palette.text.caption,
          },
        }),
        endAdornment: {
          right: '12px!important',
        },
        root: ({ theme }) => ({
          '& .MuiInputBase-root': {
            paddingLeft: '16px',
          },
          '& .Mui-disabled .MuiAutocomplete-endAdornment svg': {
            color: alpha(theme.palette.text.caption, 0.4),
          },
        }),
        popper: ({ theme }) => ({
          '& .MuiAutocomplete-listbox': {
            maxHeight: '255px',
          },
          '& .MuiAutocomplete-option:hover': {
            backgroundColor: alpha(
              theme.palette.background.cardExtraLayer,
              0.05,
            ),
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: 'transparent !important',
          },

          '& .MuiAutocomplete-option[aria-selected="true"]:hover': {
            backgroundColor: `${alpha(
              theme.palette.background.cardExtraLayer,
              0.05,
            )} !important`,
          },
        }),
        noOptions: ({ theme }) => ({
          ...theme.typography.p1,
          color: theme.palette.text.heading,
        }),
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          warning: <InfoOutlined />,
          error: <InfoOutlined />,
          info: <InfoOutlined />,
          success: <CheckCircle />,
        },
        components: {
          CloseIcon: AlertClose,
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.p2,
          color: theme.palette.text.heading,
          '& .MuiAlert-icon': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiAlert-action .MuiButtonBase-root': {
            padding: '3px',
          },
        }),
      },
      variants: [
        {
          props: { severity: 'warning' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.status.warning20,
            '& svg': {
              color: theme.palette.status.warning,
            },
          }),
        },
        {
          props: { severity: 'error' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.status.error15,
            '& svg': {
              color: theme.palette.error.main,
            },
          }),
        },
        {
          props: { severity: 'info' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.light10,
            '& svg': {
              color: theme.palette.primary.main,
            },
          }),
        },
        {
          props: { severity: 'success' },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.status.success20,
          }),
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          backgroundColor: '#09093299',
          backdropFilter: 'blur(10px)',
        },
      },
    },
  },
  zIndex: {
    dialog: 1300,
    globalLoader: 1375,
    menu: 1351,
  },
  spacing: 8,
});

const darkTheme = createTheme({
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    mode: 'dark',
  },
});

export { lightTheme, darkTheme };
