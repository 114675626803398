import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

interface InfoComponent extends FC<IconsProps> {
  Small: FC<IconsProps>;
}

const InfoOutlined: InfoComponent = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    color="#D2373C"
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z"
      fill="currentColor"
    />
    <path
      d="M12 13L12 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);

const InfoOutlinedSmall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
      stroke="#2B57CD"
      strokeWidth="1.33333"
      strokeMiterlimit="10"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.33268 5.33333C7.33268 4.96514 7.63116 4.66667 7.99935 4.66667C8.36754 4.66667 8.66602 4.96514 8.66602 5.33333C8.66602 5.70152 8.36754 6 7.99935 6C7.63116 6 7.33268 5.70152 7.33268 5.33333Z"
      fill="#2B57CD"
    />
    <path
      d="M8 7.3335L8 11.3335"
      stroke="#2B57CD"
      strokeWidth="1.33333"
      stroke-linejoin="round"
    />
  </svg>
);

InfoOutlined.Small = InfoOutlinedSmall;

export default InfoOutlined;
