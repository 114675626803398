import { FC } from 'react';

import { IconsProps } from '@/components/icons/types';

interface CheckBox extends FC<IconsProps> {
  Small: FC<IconsProps>;
}

const CheckBoxUnchecked: CheckBox = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="5"
      width="14"
      height="14"
      rx="3"
      stroke="#D7D7EC"
      strokeWidth="2"
    />
  </svg>
);

const Small: CheckBox['Small'] = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
    >
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="3"
        stroke="#D7D7EC"
        strokeWidth="2"
      />
    </svg>
  );
};

CheckBoxUnchecked.Small = Small;

export default CheckBoxUnchecked;
